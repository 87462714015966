<template>

    <div class="popup active">
        <div class="prjdt-popup">
            <div class="close">
                <img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
            </div>
            
            <ProjectInfo :projectSeq="Number(param.projectSeq)" type="6" @goList="$emit('close')"/>
           
        </div>
    </div>

</template>

<script>

import ProjectInfo from '@/components/highpro/ProjectInfo.vue';

export default {

	components : {
		ProjectInfo
	},

    props: ['param'],

	data() {
		return {		
		};
	},
	beforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	mounted() {
		// console.log('mounted');
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		
	},
};
</script>
